import React from "react";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../BackToTop";
import ZainHeader from "./ZainHeader";
import Banner from "./Banner";
import About from "./About";
// import OurVision from "./OurVision";
import OurClient from "./OurClient";
import WhatWeOffer from "./WhatWeOffer";
import TechStack from "./TechStack";
// import ContactForms from "./ContactForms";
import ContactSection from "./ContactSection";
import Footer from "./Footer";
import ClientReviews from "./ClientReviews";
import ZainDrawer from "./ZainDrawer";

function ZainWeb() {
  const [drawer, drawerAction] = useToggle(false);

  return (
    <>
      <ZainDrawer drawer={drawer} action={drawerAction.toggle} />
      <ZainHeader action={drawerAction.toggle} />
      <Banner />
      <About />
      {/* <OurVision /> */}
      <WhatWeOffer />
      <OurClient />
      <TechStack />
      <ClientReviews />
      {/* <ContactForms /> */}
      <ContactSection />
      <Footer />
      <BackToTop />
    </>
  );
}

export default ZainWeb;
