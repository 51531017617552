import React from "react";

function ContactSection() {
  return (
    <div id="contact_us">
      <div className="appie-about-top-title-area pt-24 pb-8">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="appie-about-top-title">
                <h2 className="title">Contact us</h2>
                <p>Get in touch</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="appie-project-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="appie-project-box wow animated slideInUp"
                data-wow-duration="1000ms"
                data-wow-delay="0ms"
              >
                <div className="grid lg:grid-cols-2 gap-8">
                  <div className="">
                    <div className="appie-project-content">
                      <h3 className="title">
                        Start your project with ZainWeb.
                      </h3>
                      {/* <p>We will get back to you via email.</p>
                      <form action="#">
                        <div className="input-box mt-30">
                          <input type="text" placeholder="Your email" />
                          <button type="button">Subscribe</button>
                        </div>
                      </form> */}
                      {/* <h5>Office</h5>
                      <p className="text-white mt-2">
                        <i className="fal  fa-map-marker-alt text-white pe-2"></i>
                        Muslim Nagar, Near Munshi Masjid,
                        <br /> Dhule 424001
                      </p> */}
                    </div>
                  </div>
                  <div className="">
                    <div className="appie-project-content">
                      <div className="grid sm:grid-cols-2">
                        <div className="single-info">
                          <h5>Phone</h5>
                          <p className="text-white">
                            <i className="fal fa-phone text-white font-light"></i>
                            <a className="text-white" href="tel:+917276492239">
                              (+91) 7276492239
                            </a>{" "}
                            <a className="text-white" href="tel:+919421080313">
                              (+91) 9421080313
                            </a>
                          </p>
                        </div>
                        <div className="single-info">
                          <h5>Support</h5>
                          <p>
                            <a
                              className="text-white"
                              href="mailto:zainweb14722@gmail.com"
                            >
                              <i className="fal fa-envelope text-white" />{" "}
                              zainweb14722@gmail.com
                            </a>
                          </p>
                        </div>
                        <div className="single-info">
                          <h5>Office</h5>
                          <p className="text-white">
                            <i className="fal  fa-map-marker-alt text-white"></i>
                            Muslim Nagar, Near Munshi Masjid,
                            <br /> Dhule 424001
                          </p>
                        </div>
                        {/* <div className="ab-social">
                          <h5>Follow Us</h5>
                          <div className="flex flex-wrap gap-2">
                            <a className="fac" href="#">
                              <i className="fab fa-facebook-f "></i>
                            </a>
                            <a className="twi" href="#">
                              <i className="fab fa-twitter"></i>
                            </a>
                            <a className="you" href="#">
                              <i className="fab fa-youtube"></i>
                            </a>
                            <a className="lin" href="#">
                              <i className="fab fa-linkedin-in"></i>
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="appie-project-thumb">
                  <img src="assets/images/project-thumb.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bisylms-map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3412.092781135499!2d74.78302091360088!3d20.889057463210293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e1!3m2!1sen!2sin!4v1715913951395!5m2!1sen!2sin"
          width="600"
          height="450"
          // style="border:0;"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}

export default ContactSection;
