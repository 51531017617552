import React from "react";
function ZainDrawer({ drawer, action }) {
  // const [itemSize, setSize] = useState("0px");
  // const [item, setItem] = useState("home");
  // const handler = (e, value) => {
  //   e.preventDefault();
  //   const getItems = document.querySelectorAll(`#${value} li`).length;
  //   if (getItems > 0) {
  //     setSize(`${43 * getItems}px`);
  //     setItem(value);
  //   }
  // };
  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${drawer ? "active" : ""}`}
      ></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
              >
                <div className="canvas_close">
                  <a href="#" onClick={(e) => action(e)}>
                    <i className="fa fa-times text-black"></i>
                  </a>
                </div>
                <div className="offcanvas-brand text-center mb-40">
                  <h4 className="font-extrabold text-bas">ZainWeb</h4>
                  {/* <img src={logo} alt="" /> */}
                </div>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    <li className="menu-item-has-children active">
                      <a href="/#about_us">About</a>
                    </li>
                    <li className="menu-item-has-children active">
                      <a href="/#our_clients">Clients</a>
                    </li>
                    <li className="menu-item-has-children active">
                      <a href="/#contact_us">Contact</a>
                    </li>
                  </ul>
                </div>
                {/* <div className="offcanvas-social">
                  <ul className="text-center">
                    <li>
                      <a href="$">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="$">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="$">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="$">
                        <i className="fab fa-dribbble"></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div className="footer-widget-info">
                  <ul>
                    <li>
                      <a href="mailto:zainweb14722@gmail.com">
                        <i className="fal fa-envelope" /> zainweb14722@gmail.com
                      </a>
                    </li>
                    <li>
                      <a href="tel:+917276492239">
                        <i className="fal fa-phone" /> +91 7276492239
                      </a>{" "}
                      <a href="tel:+919421080313">
                        <i className="fal fa-phone" /> +91 9421080313
                      </a>
                    </li>
                    <li>
                      <i className="fal fa-map-marker-alt" /> Muslim Nagar, Near
                      Munshi Masjid, Dhule 424001
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ZainDrawer;
