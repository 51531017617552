import Client1 from "../assets/images/clients/islamic_day.jfif";
import Client2 from "../assets/images/clients/HHH_school.jfif";
import Client3 from "../assets/images/clients/zen_max.jfif";
import Client4 from "../assets/images/clients/ali_abbas_construction.jfif";

export const whatWeOffer = [
  {
    icon: "fas fa-store",
    title: "Vendor Services",
    description:
      "Get access to a wide range of products and services from top vendors, tailored to meet your specific needs.",
    itemClass: "",
  },
  {
    icon: "fas fa-tools",
    title: "Maintenance Support",
    description:
      "Ensure the smooth operation of your systems with our comprehensive maintenance support services, available whenever you need them.",
    itemClass: "item-2",
  },
  {
    icon: "fas fa-cogs",
    title: "Full-Service Solutions",
    description:
      "Experience the convenience of having all your digital needs met under one roof, with our comprehensive full-service solutions tailored to your requirements.",
    itemClass: "item-3",
  },
  {
    icon: "fas fa-user-tie",
    title: "Freelancer Services",
    description:
      "Hire skilled freelancers for your projects, ensuring flexibility and cost-effectiveness while maintaining high-quality standards.",
    itemClass: "item-4",
  },
];

export const aobutUs = [
  {
    icon: "fas fa-eye",
    title: "Our Vision",
    description:
      "Illuminating the Future. We're driven by a vision to revolutionize the digital landscape, pioneering innovative solutions that propel businesses to new heights.",
  },
  {
    icon: "fas fa-bullseye",
    title: "Our Mission",
    description:
      "Your Digital Partner. Our mission is simple yet powerful: to empower businesses with game-changing digital solutions, fueling growth and success in the online world.",
  },
  {
    icon: "fas fa-bullseye-arrow",
    title: "Our Goals",
    description:
      "Turning Dreams into Reality. We're committed to realizing your digital ambitions, crafting strategies that drive results and exceed expectations.",
  },
];

export const clientsData = [
  {
    name: "Islamic Day School",
    link: "https://schools.org.in/dhule/27020500308/islamic-day-school-vadjai-rd.html",
    image: Client1,
    description:
      "We provided Islamic Day School with a comprehensive IT infrastructure upgrade, ensuring seamless digital learning experiences for their students.",
  },
  {
    name: "HHH MODEL SCHOOL",
    link: "https://schools.org.in/dhule/27020500321/haji-hashim-husain-model-school-pri.html",
    image: Client2,
    description:
      "Our team developed a custom e-learning platform for HAJI HASHIM HUSAIN MODEL SCHOOL, enhancing their educational offerings with cutting-edge technology.",
  },
  {
    name: "Zen Max Trader's",
    link: "https://zenmaxtrader.in",
    image: Client3,
    description:
      "Zen Max Trader's benefited from our advanced digital marketing strategies and website optimization services, boosting their online presence and market reach.",
  },
  {
    name: "Ali Abbas Construction",
    link: "",
    image: Client4,
    description:
      "We delivered robust project management software solutions to Ali Abbas Construction, streamlining their operations and improving project efficiency.",
  },
];

export const clientReviews = [
  {
    name: "Islamic Day School",
    image: Client1,
    title: "Educational Institution",
    review:
      "ZainWeb provided exceptional IT services tailored to our school's needs. Their solutions helped streamline our administrative tasks and enhance our online presence, making it easier for parents and students to stay connected. We're extremely satisfied with their professionalism and expertise.",
  },
  {
    name: "HAJI HASHIM HUSAIN MODEL SCHOOL",
    image: Client2,
    title: "Educational Institution",
    review:
      "The team at ZainWeb has been instrumental in modernizing our school's IT infrastructure. They delivered innovative solutions that significantly improved our operational efficiency and communication with parents. Their dedication and support have been outstanding.",
  },
  {
    name: "Zen Max Trader's",
    image: Client3,
    title: "Trading Company",
    review:
      "Working with ZainWeb has been a game-changer for our business. Their custom IT solutions helped us optimize our trading processes and improve our online security. Their team is knowledgeable, responsive, and always willing to go the extra mile to meet our needs.",
  },
  {
    name: "Ali Abbas Construction",
    image: Client4,
    title: "Construction Company",
    review:
      "ZainWeb delivered top-notch IT services that perfectly aligned with our construction company's requirements. Their innovative solutions enhanced our project management and communication systems, making our operations smoother and more efficient. We highly recommend their services.",
  },
  {
    name: "Zen Max Trader's",
    image: Client3,
    title: "Trading Company",
    review:
      "Working with ZainWeb has been a game-changer for our business. Their custom IT solutions helped us optimize our trading processes and improve our online security. Their team is knowledgeable, responsive, and always willing to go the extra mile to meet our needs.",
  },
];
