import React from "react";
import banner from "../../assets/images/banner1.jpg";

function Banner() {
  return (
    <section className="appie-hero-area appie-hero-6-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="appie-hero-thumb-6">
              <div
                className="thumb wow animated fadeInUp"
                data-wow-duration="1000ms"
                data-wow-delay="600ms"
              >
                <img src={banner} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="appie-hero-content appie-hero-content-6">
              <span>Welcome To Zain Web.</span>
              <h1 className="appie-title">Elevating Your Online Presence.</h1>
              <p>
                Your one-stop destination for expert web services. Let us take
                your online presence to new heights.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
