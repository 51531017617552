import React from "react";
import { whatWeOffer } from "../../lib/data";
// import IconOne from "../../assets/images/icon/1.png";
// import IconTwo from "../../assets/images/icon/2.png";
// import IconThree from "../../assets/images/icon/3.png";
// import IconFour from "../../assets/images/icon/4.png";

function WhatWeOffer({ className }) {
  return (
    <section
      className={`appie-service-area pt-90 pb-100 ${className}`}
      id="our_services"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="appie-section-title text-center">
              <h3 className="appie-title">
                Elevate Your Business <br /> with Our Services.
              </h3>
              <p>
                We offer comprehensive solutions tailored to your needs,
                ensuring your business thrives in the digital landscape.
              </p>
            </div>
          </div>
        </div>
        <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 md:gap-8">
          {whatWeOffer.map((offer, index) => (
            <div key={index} className="h-full w-full">
              <div
                className={`appie-single-service text-center mt-30 wow animated fadeInUp h-full ${offer.itemClass}`}
                data-wow-duration="2000ms"
                data-wow-delay={`${(index + 1) * 200}ms`}
              >
                <div className="icon">
                  <i className={`text-white text-lg ${offer.icon}`} />
                </div>
                <h4 className="appie-title">{offer.title}</h4>
                <p>{offer.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default WhatWeOffer;

// <div className=" h-full w-full">
//   <div
//     className="appie-single-service text-center mt-30 wow animated fadeInUp h-full"
//     data-wow-duration="2000ms"
//     data-wow-delay="200ms"
//   >
//     <div className="icon">
//       <i className="text-white text-lg fas fa-store" />
//       {/* <img src={IconOne} alt="" />
//       <span>1</span> */}
//     </div>
//     <h4 className="appie-title">Vendor Services</h4>
//     <p>
//       Get access to a wide range of products and services from top
//       vendors, tailored to meet your specific needs.
//     </p>
//   </div>
// </div>
// <div className=" h-full w-full">
//   <div
//     className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp h-full"
//     data-wow-duration="2000ms"
//     data-wow-delay="400ms"
//   >
//     <div className="icon">
//       <i className="text-white text-lg fas fa-tools" />

//       {/* <img src={IconTwo} alt="" />
//       <span>2</span> */}
//     </div>
//     <h4 className="appie-title">Maintenance Support</h4>
//     <p>
//       Ensure the smooth operation of your systems with our
//       comprehensive maintenance support services, available whenever
//       you need them.
//     </p>
//   </div>
// </div>
// <div className=" h-full w-full">
//   <div
//     className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp h-full"
//     data-wow-duration="2000ms"
//     data-wow-delay="600ms"
//   >
//     <div className="icon">
//       <i className="text-white text-lg fas fa-cogs" />

//       {/* <img src={IconThree} alt="" />
//       <span>3</span> */}
//     </div>
//     <h4 className="appie-title">Full-Service Solutions</h4>
//     <p>
//       Experience the convenience of having all your digital needs met
//       under one roof, with our comprehensive full-service solutions
//       tailored to your requirements.
//     </p>
//   </div>
// </div>
// <div className=" h-full w-full">
//   <div
//     className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp h-full"
//     data-wow-duration="2000ms"
//     data-wow-delay="800ms"
//   >
//     <div className="icon">
//       <i className="text-white text-lg fas fa-user-tie" />
//       {/* <img src={IconFour} alt="" />
//       <span>4</span> */}
//     </div>
//     <h4 className="appie-title">Freelancer Services</h4>
//     <p>
//       Hire skilled freelancers for your projects, ensuring flexibility
//       and cost-effectiveness while maintaining high-quality standards.
//     </p>
//   </div>
// </div>
