import React from "react";

import { clientsData } from "../../lib/data";

function OurClient() {
  return (
    <section className="appie-blog-3-area pt-90 pb-100" id={"our_clients"}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="appie-section-title text-center">
              <h3 className="appie-title">Our Valued Clients</h3>
              <p>
                Discover some of the companies we've had the pleasure to work
                with.
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
          {clientsData.map((client, index) => (
            <div key={index} className="h-full">
              <div className="appie-blog-item-3 h-full">
                <div className="thumb">
                  <img src={client.image} alt={client.name} />
                </div>
                <div className="content">
                  <h5 className="title">
                    <a href={client.link} target="_blank" rel="noreferrer">
                      {client.name}
                    </a>
                  </h5>
                  <div className="meta-item">
                    <p>{client.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default OurClient;

// <div className="col-lg-6">
//   <div className="appie-blog-item-3 mt-30">
//     <div className="thumb">
//       <img src={BlogFour} alt="" />
//     </div>
//     <div className="content">
//       <h5 className="title">
//         <a href="/" target="_blank">
//           ABC Corporation
//         </a>
//       </h5>
//       <div className="meta-item">
//         <p>
//           Leading provider of innovative solutions in the tech
//           industry. We've collaborated on numerous projects,
//           delivering exceptional results.
//         </p>
//         {/* <ul>
//           <li>
//             <i className="fal fa-clock" /> July 14, 2022
//           </li>
//           <li>
//             <i className="fal fa-comments" /> July 14, 2022
//           </li>
//         </ul> */}
//       </div>
//     </div>
//   </div>
// </div>
// <div className="col-lg-6">
//   <div className="appie-blog-item-3 mt-30">
//     <div className="thumb">
//       <img src={BlogFive} alt="" />
//     </div>
//     <div className="content">
//       <h5 className="title">
//         <a href="/" target="_blank">
//           XYZ Enterprises
//         </a>
//       </h5>
//       <div className="meta-item">
//         <p>
//           A global leader in manufacturing, XYZ Enterprises has
//           trusted us to handle their digital needs, from website
//           development to digital marketing strategies.
//         </p>
//         {/* <ul>
//           <li>
//             <i className="fal fa-clock" /> July 14, 2022
//           </li>
//           <li>
//             <i className="fal fa-comments" /> July 14, 2022
//           </li>
//         </ul> */}
//       </div>
//     </div>
//   </div>
// </div>
// <div className="col-lg-6">
//   <div className="appie-blog-item-3 mt-30">
//     <div className="thumb">
//       <img src={BlogSix} alt="" />
//     </div>
//     <div className="content">
//       <h5 className="title">
//         <a href="/" target="_blank">
//           Tech Innovations Inc.
//         </a>
//       </h5>
//       <div className="meta-item">
//         <p>
//           At the forefront of technological advancements, Tech
//           Innovations Inc. relies on our expertise to stay ahead in
//           the competitive digital landscape.
//         </p>
//         {/* <ul>
//           <li>
//             <i className="fal fa-clock" /> July 14, 2022
//           </li>
//           <li>
//             <i className="fal fa-comments" /> July 14, 2022
//           </li>
//         </ul> */}
//       </div>
//     </div>
//   </div>
// </div>
// <div className="col-lg-6">
//   <div className="appie-blog-item-3 mt-30">
//     <div className="thumb">
//       <img src={BlogSeven} alt="" />
//     </div>
//     <div className="content">
//       <h5 className="title">
//         <a href="/" target="_blank">
//           Global Solutions Ltd.
//         </a>
//       </h5>
//       <div className="meta-item">
//         <p>
//           Providing comprehensive business solutions worldwide, Global
//           Solutions Ltd. has been a longstanding partner, trusting us
//           with their digital transformation journey.
//         </p>
//         {/* <ul>
//           <li>
//             <i className="fal fa-clock" /> July 14, 2022
//           </li>
//           <li>
//             <i className="fal fa-comments" /> July 14, 2022
//           </li>
//         </ul> */}
//       </div>
//     </div>
//   </div>
// </div>
// <div className="col-lg-12">
//   <div className="blog-btn text-center mt-60">
//     <Link className="main-btn" to="/news">
//       View All Posts <i className="fal fa-arrow-right" />
//     </Link>
//   </div>
// </div>
