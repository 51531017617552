import React from "react";
import sponserShape from "../../assets/images/sponser-shape.png";

import node from "../../assets/images/tech-stack/node.png";
import expressjs from "../../assets/images/tech-stack/expressjs.png";
import php from "../../assets/images/tech-stack/php.png";
import codeigniter from "../../assets/images/tech-stack/codeigniter.png";
import laravel from "../../assets/images/tech-stack/laravel.png";
import mongodb from "../../assets/images/tech-stack/mongodb.png";
import mysql from "../../assets/images/tech-stack/mysql.png";
import nextjs from "../../assets/images/tech-stack/nextjs.png";
import rectjs from "../../assets/images/tech-stack/rectjs.png";

function TechStack({ className }) {
  const techStack = [
    node,
    expressjs,
    php,
    codeigniter,
    laravel,
    mongodb,
    mysql,
    nextjs,
    rectjs,
  ];
  return (
    <>
      <section className={`appie-sponser-area pb-100 ${className}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Explore Our Tech Stack</h3>
                <p>
                  Discover our tech stack, designed to simplify your digital
                  experience.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-sponser-box flex flex-wrap justify-center items-center">
                {techStack.map((sponsor, index) => (
                  <div key={index} className="sponser-item overflow-hidden p-4">
                    <img
                      src={sponsor}
                      alt={`Sponsor ${index + 1}`}
                      className="img-fluid max-h-full max-w-full object-contain"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="sponser-shape">
          <img src={sponserShape} alt="" />
        </div>
      </section>
    </>
  );
}

export default TechStack;

// <div className="appie-sponser-box d-flex justify-content-center">
//   <div className="sponser-item">
//     <img src={Sponser1} alt="" />
//   </div>
//   <div className="sponser-item">
//     <img src={Sponser2} alt="" />
//   </div>
//   <div className="sponser-item">
//     <img src={Sponser3} alt="" />
//   </div>
//   <div className="sponser-item">
//     <img src={Sponser4} alt="" />
//   </div>
//   <div className="sponser-item">
//     <img src={Sponser5} alt="" />
//   </div>
//   <div className="sponser-item">
//     <img src={Sponser6} alt="" />
//   </div>
// </div>
// <div className="appie-sponser-box item-2 d-flex justify-content-center">
//   <div className="sponser-item">
//     <img src={Sponser7} alt="" />
//   </div>
//   <div className="sponser-item">
//     <img src={Sponser8} alt="" />
//   </div>
//   <div className="sponser-item">
//     <img src={Sponser9} alt="" />
//   </div>
//   <div className="sponser-item">
//     <img src={Sponser10} alt="" />
//   </div>
// </div>
