import React from "react";
import { Link } from "react-router-dom";

function Footer({ className }) {
  return (
    <section className={`appie-footer-area ${className || ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-4">
            <div className="footer-about-widget">
              <div className="logo font-extrabold text-xl">
                <Link to="/">Zain Web</Link>
              </div>
              <p>
                ZainWeb is your one-stop solution for all your IT needs,
                providing top-notch services and support to help your business
                thrive in the digital age.
              </p>
              <a href="/#about_us">
                Read More <i className="fal fa-arrow-right" />
              </a>
              <div className="social mt-30">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="footer-navigation">
              <h4 className="title">Company</h4>
              <ul>
                <li>
                  <a href="/#about_us">About</a>
                </li>
                <li>
                  <a href="/#our_services">Services</a>
                </li>
                <li>
                  <a href="/#our_clients">Clients</a>
                </li>
                <li>
                  <a href="/#contact_us">Contact</a>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-4">
            <div className="footer-navigation">
              <h4 className="title">Support</h4>
              <ul>
                <li>
                  <Link to="/faq">FAQs</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-of-service">Terms of Service</Link>
                </li>
                <li>
                  <Link to="/support">Support</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="col-lg-3 col-md-4">
            <div className="footer-widget-info">
              <h4 className="title">Get In Touch</h4>
              <ul>
                <li>
                  <a href="mailto:zainweb14722@gmail.com">
                    <i className="fal fa-envelope" /> zainweb14722@gmail.com
                  </a>
                </li>
                <li>
                  <a href="tel:+917276492239">
                    <i className="fal fa-phone" /> +91 7276492239
                  </a>{" "}
                  <a href="tel:+919421080313">
                    <i className="fal fa-phone" /> +91 9421080313
                  </a>
                </li>
                <li>
                  <i className="fal fa-map-marker-alt" /> Muslim Nagar, Near
                  Munshi Masjid, Dhule 424001
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
              {/* <div className="apps-download-btn">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fab fa-apple" /> Download for iOS
                    </a>
                  </li>
                  <li>
                    <a className="item-2" href="#">
                      <i className="fab fa-google-play" /> Download for Android
                    </a>
                  </li>
                </ul>
              </div> */}
              <div className="copyright-text">
                <p>Copyright © 2024 ZainWeb. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
