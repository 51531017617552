import React from "react";
import { aobutUs } from "../../lib/data";

function About() {
  return (
    <div id="about_us">
      <div className="appie-about-top-title-area pt-24 pb-8">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="appie-about-top-title">
                <h2 className="title">About us</h2>
                {/* <p>We offer you a panoply of cutting-edge options.</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="appie-about-page-area m-0 py-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="appie-about-page-content  before:bg-none">
                <h3 className="title">
                  Empowering Businesses with Innovative Solutions.
                </h3>
                <p>
                  At Zain Web, we pride ourselves on being the leading people
                  management platform. Join our team as we strive to create the
                  best digital solutions that drive success for businesses
                  worldwide. Our mission is to revolutionize the digital
                  landscape and empower businesses to reach their full
                  potential.
                </p>
                <a href="#">
                  Read more <i className="fal fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>

          <div className=" bg-gray-100/50 pb-4 px-4 rounded-xl mt-8 m-[1px]">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {aobutUs.map((service, index) => (
                <div key={index} className="w-full h-[90%]">
                  <div
                    className={`w-full h-full bg-white appie-single-service-2 mt-30 wow animated fadeInUp ${index % 2 !== 0 && `item-2`}`}
                    data-wow-duration="2000ms"
                    data-wow-delay={`${(index + 1) * 200}ms`}
                  >
                    <div className="icon">
                      <i className={service.icon} />
                    </div>
                    <h4 className="title">{service.title}</h4>
                    <p>{service.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;

// <div className="w-full h-[90%] ">
//   <div
//     className="w-full h-full bg-white appie-single-service-2 mt-30 wow animated fadeInUp"
//     data-wow-duration="2000ms"
//     data-wow-delay="200ms"
//   >
//     <div className="icon">
//       <i className="fas fa-eye" />
//     </div>
//     <h4 className="title">Our Vision</h4>
//     <p>
//       Illuminating the Future. We're driven by a vision to
//       revolutionize the digital landscape, pioneering innovative
//       solutions that propel businesses to new heights.
//     </p>
//     {/* <a href="#">
//     Read Mor <i className="fal fa-arrow-right" />
//   </a> */}
//   </div>
// </div>
// <div className="w-full h-[90%] ">
//   <div
//     className="w-full h-full bg-white appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
//     data-wow-duration="2000ms"
//     data-wow-delay="400ms"
//   >
//     <div className="icon">
//       <i className="fas fa-bullseye" />
//     </div>
//     <h4 className="title">Our Mission</h4>
//     <p>
//       Your Digital Partner. Our mission is simple yet powerful: to
//       empower businesses with game-changing digital solutions,
//       fueling growth and success in the online world.
//     </p>
//     {/* <a href="#">
//     Read Mor <i className="fal fa-arrow-right" />
//   </a> */}
//   </div>
// </div>
// <div className="w-full h-[90%] ">
//   <div
//     className="w-full h-full bg-white appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
//     data-wow-duration="2000ms"
//     data-wow-delay="600ms"
//   >
//     <div className="icon">
//       <i className="fas fa-bullseye-arrow" />
//     </div>
//     <h4 className="title">Our Goals</h4>
//     <p>
//       Turning Dreams into Reality. We're committed to realizing
//       your digital ambitions, crafting strategies that drive
//       results and exceed expectations.
//     </p>
//     {/* <a href="#">
//     Read Mor <i className="fal fa-arrow-right" />
//   </a> */}
//   </div>
// </div>
