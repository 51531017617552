import React from "react";

function Navigation() {
  return (
    <>
      <ul>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/#about_us">About</a>
        </li>
        <li>
          <a href="/#our_services">Services</a>
        </li>
        <li>
          <a href="/#our_clients">Clients</a>
        </li>
        <li>
          <a href="/#contact_us">Contact</a>
        </li>
      </ul>
    </>
  );
}

export default Navigation;
